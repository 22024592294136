<template>
  <b-modal id="action-Plan" @hidden="$emit('resetModal')" :title="selectedPlan ? 'Update Plan' : 'Add Plan'"
    hide-footer>
    <FormulateForm name="PlanAction" v-model="values" :schema="schema" @submit="submitHandler">
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedPlan"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedPlan"> Update </span>
          <span v-else> Add </span>
          Plan</span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { plans } from "@/config/api/plans";
export default {
  props: ["selectedPlan"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name Arabic",
          validation: "required",
        },
        {
          index: 7,
          type: "text",
          name: "name_ar",
          label: "Name Arabic",
          validation: "required",
        },
        {
          component: "div",
          class: "double-row",
          children: [
            {
              index: 1,
              type: "number",
              name: "price",
              min: "0",
              label: "Price",
              validation: "required",
            },
            {
              index: 6,
              type: "number",
              name: "no_of_days",
              min: "0",
              label: "No. of Days",
              validation: "required",
            },
            {
              index: 2,
              type: "number",
              name: "no_of_vehicles",
              label: "No. of vehicles",
              min: "0",
              validation: "required",
              validationMessages: {
                required: "Number of vehicle is required.",
              },
            },
            {
              index: 5,
              type: "number",
              name: "flat_rate",
              label: "Flat rate",
              step: "0.01",
              min: "0",
              validation: "required",
              validationMessages: {
                required: "Flat rate is required.",
              },
            },
          ],
        },
        {
          index: 3,
          type: "tags-input",
          validation: "required",
          label: "Features English",
          name: "features",
        },
        {
          index: 8,
          label: "Features Arabic",
          type: "tags-input",
          validation: "required",
          name: "features_ar",
        },
        {
          index: 4,
          type: "radio",
          class: "payment-duration",
          name: "payment_duration",
          options: ["Month", "Year"],
          value: 'Month',
          label: "Payment Duration",
          validation: "required",
          validationMessages: {
            required: "Number of vehicle is required.",
          },
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      if (this.selectedPlan) {
        this.updatePlan(data, this.selectedPlan._id);
      } else {
        this.addPlan(data);
      }
    },
    addPlan(data) {
      this.performAction = true;
      const api = plans.create;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$formulate.reset("PlanAction");
          this.$bvToast.toast("Plan added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Plan is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updatePlan(data, id) {
      this.performAction = true;
      const api = plans.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Plan updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Plan is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedPlan(val) {
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}

.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>
